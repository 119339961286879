<template>
  <div class="row">

    <div class="col-7">
        <b-card-code title="Transaction Detail">
            <table class="table table-hover table-ml">
                <tr>
                    <td class="pl-0">ID</td>
                    <td>
                      {{ transaction.id }}
                      <b-dropdown
                          variant="success"
                          size="sm"
                          class="float-right"
                          v-if="checkPermission('cancel auction transaction')"
                      >
                          <template #button-content>
                            <eye-icon size="1x" class="custom-class"></eye-icon> Action
                          </template>
                          <b-dropdown-item v-if="checkPermission('cancel auction transaction')" @click="cancelTransaction()">Cancel Transaction</b-dropdown-item>
                      </b-dropdown>
                    </td>
                </tr>
                <tr>
                    <td class="pl-0">UUID</td>
                    <td>{{ transaction.uuid }}</td>
                </tr>
                <tr>
                    <td class="pl-0">User</td>
                    <td>{{ transaction.user.code }} - {{ transaction.user.email }}</td>
                </tr>
                <tr>
                    <td class="pl-0">Type</td>
                    <td style="text-transform: capitalize;">{{ transaction.type }}</td>
                </tr>
                <tr>
                    <td class="pl-0">Transaction Time</td>
                    <td>{{ transaction.time }}</td>
                </tr>
                <tr>
                    <td class="pl-0">Status</td>
                    <td style="text-transform: capitalize;">{{ transaction.status }}</td>
                </tr>
                <tr>
                    <td class="pl-0">Commodity</td>
                    <td>{{ transaction.auction.commodity.name }}</td>
                </tr>
                <tr>
                    <td class="pl-0">Depository</td>
                    <td>{{ transaction.auction.warehouse.name }} - {{ transaction.auction.warehouse.location }}</td>
                </tr>
                <tr>
                    <td class="pl-0" style="text-transform: capitalize;">{{ transaction.type }} Price</td>
                    <td>{{ transaction.price_formatted }}</td>
                </tr>
                <tr>
                    <td class="pl-0">Lot</td>
                    <td>{{ transaction.lot }} lots</td>
                </tr>
                <tr>
                    <td  class="p-0" style="text-transform: capitalize;">{{ transaction.type }} Qty</td>
                    <td>{{ transaction.auction.commodity.amount_per_lot * transaction.lot }} {{ transaction.auction.commodity.unit }}</td>
                </tr>
            </table>
        </b-card-code>
    </div>
    <div class="col-5">
        <b-card-code title="Result Detail">
            <table class="table table-hover table-ml">
                <tr>
                    <td class="pl-0">Match Price</td>
                    <td>
                      {{ transaction.fixed_price_formatted }}
                    </td>
                </tr>
                <tr>
                    <td class="pl-0">Matched Lot</td>
                    <td>{{ transaction.lot_complete }} lots</td>
                </tr>
                <tr v-if="transaction.lot_open > 0">
                    <td class="pl-0">Open/Unmatched Lot</td>
                    <td>{{ transaction.lot_open }} lots</td>
                </tr>
                <tr>
                    <td class="pl-0">Qty</td>
                    <td>{{ transaction.qty_complete }} {{ transaction.auction.commodity.unit }}</td>
                </tr>
            </table>
        </b-card-code>
        <b-card-code title="Payment Detail">
          <table class="table table-hover table-ml">
            <tr>
                <td class="pl-0">Transaction Amount</td>
                <td>{{ transaction.net_value_formatted }}</td>
            </tr>
            <tr>
                <td class="pl-0">Fee Transaction + VAT</td>
                <td>{{ transaction.admin_fee_formatted }}</td>
            </tr>
            <tr>
                <td class="pl-0">Total Transaction Value</td>
                <td>{{ transaction.type == 'bid' ? transaction.total_buy_price_formatted : transaction.total_sell_price_formatted }}</td>
            </tr>
            <tr v-if="transaction.type == 'bid' && transaction.total_buy_paid > 0 && transaction.buy_margin_paid > 0">
                <td class="pl-0">Margin</td>
                <td>{{ transaction.buy_margin_paid_formatted }}</td>
            </tr>
            <tr v-if="transaction.type == 'bid' && transaction.total_buy_paid > 0 && transaction.total_unpaid > 0">
                <td class="pl-0">Paid Total</td>
                <td>{{ transaction.total_buy_paid_formatted }}</td>
            </tr>
            <tr v-if="transaction.type == 'bid' && transaction.total_unpaid > 0">
                <td class="pl-0">Outstanding Transaction</td>
                <td>{{ transaction.total_unpaid_formatted }}</td>
            </tr>
            <tr v-if="transaction.type == 'ask' && transaction.sell_margin_paid > 0">
                <td class="pl-0">Margin</td>
                <td>{{ transaction.sell_margin_paid }}</td>
            </tr>
            <tr v-if="transaction.payment_due_date">
                <td class="pl-0">Settlement Date</td>
                <td>{{ transaction.payment_due_date }}</td>
            </tr>
          </table>
          <b-button v-if="checkPermission('add payment auction transaction') && transaction.type == 'bid' && transaction.total_unpaid > 0" v-b-modal.form-add-payment class="btn btn-info btn-block mb-1 mt-1" data-toggle="tooltip" data-placement="center" title="Add Payment">
            <feather-icon icon="PlusCircleIcon" /> Add Payment
          </b-button>

          <b-modal id="form-add-payment" size="sm" title="Add Payment">
            <div class="form">

              <h5>Add Payment</h5>

              <div class="form-group" v-if="transaction.trade_allocations.length > 0">
                <label for="amount">For TA:</label>
                <div class="input-group">
                  <select v-model="formPayload.trade_allocation_id" class="form-control">
                    <option v-for="tradeAllocation in transaction.trade_allocations" :value="tradeAllocation.id" :key="tradeAllocation.id">{{ tradeAllocation.ta_number }}</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label for="amount">Amount:</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">USD</span>
                  </div>
                  <input type="text" class="form-control" id="amount" v-model="formPayload.amount" placeholder="Insert payment amount" required>
                </div>
              </div>

            </div>

            <template #modal-footer="{}">
              <div v-if="isLoading">
                <br>
                <b-spinner
                  class="mb-2"
                  variant="primary"
                /><br>
              </div>
              <b-button variant="success" @click="addPayment()" v-bind:disabled="isLoading">
                Submit
              </b-button>
            </template>
          </b-modal>

          <div v-if="transaction.installments.length > 0">
            <hr>
            <h5>Payment History</h5>
            <table class="table">
              <tr>
                <th class="pl-0">Time</th>
                <th>Amount</th>
              </tr>
              <tr v-for="(item, key) in transaction.installments" :key="key">
                <td class="pl-0">{{ item.time }}</td>
                <td>{{ item.amount_formatted }}</td>
              </tr>
            </table>
          </div>
        </b-card-code>
    </div>
    <div class="col-12">
        <b-card-code no-body title="Trade Allocations">
            <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>TA Number</th>
                  <th v-if="transaction.type == 'ask'">Buyer</th>
                  <th v-if="transaction.type == 'bid'">Seller</th>
                  <th>Lot</th>
                  <th>Price</th>
                  <th>Net Value</th>
                  <th>CDN</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(matched) in transaction.matcheds">
                  <tr v-for="tradeAllocation in matched.trade_allocations" :key="tradeAllocation.id">
                    <td>{{ tradeAllocation.ta_number }}</td>
                    <td v-if="transaction.type == 'ask'">{{ matched.bid_transaction.user.code }}</td>
                    <td v-if="transaction.type == 'bid'">{{ matched.ask_transaction.user.code }}</td>
                    <td>{{ tradeAllocation.lot }} Lot ({{ tradeAllocation.amount.toFixed(5) }} {{ transaction.auction.commodity.unit }})</td>
                    <td>{{ tradeAllocation.price }}</td>
                    <td>
                      {{ tradeAllocation.net_value_formatted }}
                      <br> <small>Paid: {{ tradeAllocation.total_paid_formatted || '-' }}</small>
                    </td>
                    <td>
                      <ul>
                        <li v-for="(cdn, index) in tradeAllocation.cdn_details" v-bind:key="index">{{ cdn.cdn_number }} <strong>({{ cdn.amount.toFixed(5) }} {{ transaction.auction.commodity.unit }})</strong></li>
                      </ul>
                    </td>
                    <td>
                      <button @click="doSettlementTa(tradeAllocation.id)" v-if="!tradeAllocation.is_settlement" class="btn btn-sm btn-success"><feather-icon icon="CheckCircleIcon" />  Settlement</button>
                      <span v-if="tradeAllocation.settlement_at != null">Settlement at <strong>{{ tradeAllocation.settlement_at }}</strong></span>
                    </td>
                  </tr>
                </template>
                <tr v-if="transaction.tradeAllocations == 0">
                  <td colspan="8" class="text-center">Data is empty.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-card-code>
    </div>
    <div class="col-12">
        <b-card-code no-body title="Match List">
          <div class="table-responsive-sm">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>ID</th>
                  <th v-if="transaction.type == 'ask'">Buyer</th>
                  <th v-if="transaction.type == 'bid'">Seller</th>
                  <th>Match Price</th>
                  <th>Lot</th>
                  <th>Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(matched, index) in transaction.matcheds" v-bind:key="index">
                  <td>{{ matched.id }}</td>
                  <td v-if="transaction.type == 'ask'">{{ matched.bid_transaction.user.code }}</td>
                  <td v-if="transaction.type == 'bid'">{{ matched.ask_transaction.user.code }}</td>
                  <td>{{ matched.price }}</td>
                  <td>{{ matched.lot }} Lot ({{ matched.qty }} {{ transaction.auction.commodity.unit }})</td>
                  <td>{{ matched.time }}</td>
                  <td class="text-capitalize">
                    {{ matched.status }}
                    <small v-if="matched.settlement_at != null"><br>Settlement at <strong>{{ matched.settlement_at }}</strong></small>
                  </td>
                </tr>
                <tr v-if="transaction.matcheds.length == 0">
                  <td colspan="6" class="text-center">Data is empty.</td>
                </tr>
              </tbody>
            </table>

            <div class="alert alert-info m-1 p-1">
              <strong>Match Status:</strong><br>
              <ul>
                <li><strong>Matched</strong>: Transaction has been matched.</li>
                <li><strong>Success</strong>: Settlement complete, commodity balance successfully transferred to buyer, cash balance has been transferred to Seller.</li>
                <li><strong>Failed</strong>: Settlement failed.</li>
              </ul>
            </div>
          </div>
        </b-card-code>
    </div>

  </div>
</template>

<script>
import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification, formatRupiah, preformatFloat } from '@/auth/utils'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { EyeIcon } from 'vue-feather-icons'

export default {
  title () {
    return `Detail Transaction`
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      formatRupiah,
      preformatFloat
    }
  },
  components: {
    BCard,
    BCardText,
    BCardCode,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    EyeIcon
  },
  data() {
    return {
      uuid: this.$route.params.uuid,
      transaction: Object,
      isLoading: false,
      formPayload: {
        trade_allocation_id: null,
        amount: 0
      },
    }
  },
  async created() {
    this.getData()
  },
  methods: {
    changeAmount() {
      let amount = document.getElementById('amount')
      this.formPayload.amount = amount.value
    },
    getData() {
      this.$http.get('/admin/auction-transactions/' + this.uuid).then(response => {
        this.transaction = response.data.data;
        if (this.transaction.trade_allocations.length > 0) {
          this.formPayload.trade_allocation_id = this.transaction.trade_allocations[0].id
        }
      })
    },
    cancelTransaction() {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to cancel this transaction?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.$http.post('/admin/auction-transactions/' + this.uuid + '/cancel')
          .then(response => {

            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: type + ' success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
          })

        }
      })

    },
    addPayment() {
      this.$http.post('/admin/auction-transactions/' + this.uuid + '/add-payment', this.formPayload)
        .then(response => {

          this.$bvModal.hide('form-add-payment')
          this.getData()
          successNotification(this, 'Success', 'Auction successfully created')

          this.isLoading = false;
          this.formPayload.amount = 0;

        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }

          this.isLoading = false;
        })
    },
    doSettlementTa(taId) {
      this.$swal({
        title: 'Are you sure?',
        text: 'System will settlement this TA',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.$http.post('/admin/trade-allocations/' + taId + '/do-settlement')
          .then(response => {

            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Settlement success!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
          })

        }
      })
    }
  },
}
</script>
